@import '../../../styles/shared.scss';

.queue-tickets-outer-container {
  position: fixed;
  width: 100%;
  top: 100%;
  background: $offwhite;
  padding: 8px 0;
  border-top: $border-lightest;
  box-shadow: $shadow-light;

  &.active {
    bottom: 0;
    top: auto;
  }

  &.loading {
    .tickets-list {
      opacity: 0.3;
    }
  }

  .toggle-active-btn {
    @extend %btn-dark;
    position: absolute;
    bottom: 100%;
    margin-bottom: 12px;
    margin-left: 12px;
  }

  .toolbar {
    @extend %flex-row-middle-spread;
    padding: 8px 32px;

    .main {
      @extend %flex-row-middle-spread;
      gap: 12px;
      input {
        @extend %input-shared;
        min-width: 360px;
      }

      .tickets-actions {
        display: flex;
        gap: 12px;

        .sort-select {
          max-width: 160px;
        }

        .action-btn {
          @extend %btn-dark;
        }
      }
    }

    .secondary {
      @extend %flex-row;

      .toggle-btn {
        @extend %btn-offwhite;
        margin-left: 12px;

        &.active {
          @extend %btn-dark;
        }
      }
    }
  }

  .tickets-list {
    @extend %flex-row;
    overflow: auto;
    padding-bottom: 24px;
    min-height: 180px;
    transition: opacity 0.3s ease-in-out;

    .empty-msg {
      width: 100%;
      padding-top: 80px;
      text-align: center;
      color: $light;
    }
  }

  .num-remaining {
    position: absolute;
    left: 8px;
    bottom: 8px;
    font-size: 11px;
  }
}
